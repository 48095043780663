<template lang="html">
  <div class="row">
    <div class="col-12">
      <form-card
        v-for="(group, index) of centerGroups"
        :key="index"
        :group="group"
        v-model="form"
      ></form-card>
    </div>
    <div class="container-fluid">
      <div class="page-action">
        <el-button @click="cancel">Quay lại</el-button>
        <el-button type="primary" @click="save">Lưu lại</el-button>
      </div>
    </div>
  </div>
</template>

<script>
  import FormCard from 'src/components/UIComponents/FormCard.vue'
  import { Message, Input, Button } from 'element-ui'
  import dataFrom from './admin-form'

  let _form = {}, firstGroups;

  export default {
    components: {
      FormCard,
      ElInput: Input,
      ElButton: Button,
      Message
    },
    beforeCreate() {
      firstGroups = dataFrom[0].groups;

      firstGroups.forEach(group => {
        group.attributes.forEach(attr => {
          if (attr.multiple && _form[attr.prop]) {
            _form[attr.prop] = [];
          } else {
            _form[attr.prop] = '';
          }
          attr.value = _form[attr.prop];
        })
      })

      window.AP = this;
    },
    data () {
      return {
        centerGroups: firstGroups,
        form: _form,
      }
    },

    mounted() {
      this.$store.dispatch('setPageTitle', 'Thêm Nhân viên');
      this.$store.dispatch('setCurrentActions', [{
        label: 'Lưu lại',
        type: 'primary',
        icon: '',
        callback: this.save
      }])
    },

    methods: {
      cancel() {
        this.$router.push({name: 'AllAdmins'});
      },
      save() {
        let self = this;
        this.$validator.validateAll().then(function (result) {
          if (result) {
            self.$store.dispatch('storeStaff', self.form).then(res => {
              if (res.success) {
                Message({
                  message: 'Tạo mới thành công',
                  type: 'success'
                });
                self.$router.push({name: 'AllAdmins'})
              } else {
                if (res.errors) {
                  for (let err in res.errors) {
                    Message({
                      message: res.errors[err][0],
                      type: 'error'
                    });
                  }
                } else {
                  Message({
                    message: res.message,
                    type: 'error'
                  });
                }
              }
            }, error => {
              console.error(error);
            });
          }
        })
      }
    },
    destroyed(){
      this.$store.dispatch('setCurrentActions', []);
    }
  }
</script>
